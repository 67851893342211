<template>
  <div class="login-wrapper ">
    <!-- <span class="logo"
      ><img src="../../assets/logo.png" alt="" class="logo-img"
    /></span> -->
    <div class="login-container">
      <div class="login-form-container base-shadow">
        <div class="login-form">
          <p class="banner-title">
            <img src="../../assets/logo-deep.png" alt="" class="vg-logo" />
          </p>
          <br />
          <p class="banner-title  text-primary">网站后台管理</p>
          <br />
          <br />
          <br />
          <AccountLoginForm
            :redirect="redirect"
            v-show="loginType == 'account'"
          />
        </div>
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright-text"
        >Copyright &copy; 2023 唯格石英砖 版权所有
        <a data-v-05c68d54="" target="_blank" href="https://beian.miit.gov.cn/"
          >粤ICP备2021028384号-1</a
        >
      </span>
    </div>
  </div>
</template>

<script>
import AccountLoginForm from "./components/AccountLoginForm";
export default {
  components: {
    AccountLoginForm,
  },
  data() {
    return {
      loading: false,
      redirect: "",
      loginType: "account",
    };
  },
  created() {
    if (this.$route.query.message) {
      this.$message.error(this.$route.query.message);
    }
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect;
    }
  },
  methods: {
    toggleLoginType(type) {
      this.loginType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$base-width: 960px;
$base-color: #03a1ea;
.vg-logo {
  max-height: 80px;
}
.login-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background: url("../../assets/login.jpg") center 100%;
  overflow: hidden;

  .logo {
    z-index: 3;
    position: absolute;
    top: 10px;
    left: 30px;
    display: inline-block;
    height: 40px;
    text-align: left;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .login-container {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  .login-wrap-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 50px;
    width: $base-width;
    margin: 0 auto;

    .slogan-logo {
      max-height: 50px;
    }
    .system-name {
      display: inline-block;
      padding-left: 20px;
      margin-left: 20px;
      font-size: 32px;
      font-weight: 400;
      color: #333;
      position: relative;
      // &::before {
      //   content: "";
      //   width: 2px;
      //   height: 32px;
      //   background-color: #333;
      //   position: absolute;
      //   top: 50%;
      //   left: 0;
      //   transform: translateY(-50%);
      // }
    }
  }
  .login-form-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80%;
    margin: 0 auto;
    color: #333;
    border-radius: 10px;
    .logo-img {
      max-width: 100%;
    }
    .banner-title {
      width: 100%;
      font-size: 22px;
      text-align: center;

      &.weight {
        font-weight: 800;
      }
      &.light {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }
  .login-footer {
    z-index: 3;
    width: 100%;
    height: 60px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    .copyright-text {
      box-sizing: border-box;
      padding: 4px 10px;
      border-radius: 20px;
      color: #fff;
      // background: #ddd; /* fallback for old browsers */

      overflow: hidden;
      & a {
        color: rgb(189, 185, 185);
        &:hover {
          color: #03a1ea;
        }
      }
    }
  }
  .login-banner {
    flex: 50%;
    box-sizing: border-box;
    padding: 30px;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    .draw-content {
      width: 100%;
      // height: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .svg-draw {
        font-size: 370px;
      }
    }
  }
  .login-form {
    box-sizing: border-box;
    width: 400px;
    height: auto;
    padding: 50px;
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    background-color: rgba($color: #fff, $alpha: 0.8);
    transition: all 0.3s linear;
    position: relative;
    &:hover {
      border-radius: 10px;
      transform: translateY(-3px);
      background-color: rgba($color: #fdfbf9, $alpha: 1);
    }
    .other-login-item {
      box-sizing: border-box;
      padding: 10px 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: #000;
      .other-login-title {
        padding-right: 20px;
      }
      .other-login-icon {
        font-size: 24px;
        transition: all 0.3s linear;
        cursor: pointer;
        color: #989898;
        &.wechat {
          &:hover {
            color: #52c41a;
          }
        }
      }
    }
  }
  .toggle-btn-group {
    box-sizing: border-box;
    padding: 0 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    .toggle-btn {
      flex: 1;
      box-sizing: border-box;
      padding: 10px 10px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      &:hover {
        color: $theme_color;
      }
      &.active {
        transition: all 0.3s linear;
        background-color: $theme_color;
        color: #fff;
        transform: translateY(-1px);
      }
    }
  }
}
</style>
